import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import * as Sentry from '@sentry/react'

import { GTM_ID, HOTJAR_ID, HOTJAR_SV } from '@src/constants'
import { useGetMyAccount } from '@queries/Account'
import { useGetTeamSubscription } from '@queries/Billing'

const tagManagerArgs = {
  gtmId: GTM_ID || '',
}
if (GTM_ID) {
  TagManager.initialize(tagManagerArgs)
}

const use3rdParties = () => {
  const { data: user } = useGetMyAccount()
  const { data: subscription } = useGetTeamSubscription({ enabled: !!user })

  useEffect(() => {
    if (user?.username && user?.team && subscription) {
      Sentry.setUser({ id: user.id, name: user.team.name, username: user.team.name })

      if (GTM_ID) {
        TagManager.dataLayer({
          dataLayer: {
            user_id: user.id,
          },
        })
      }

      if (window.Intercom) {
        window.Intercom('update', {
          name: user.name,
          email: user.email,
          user_id: user.id.toString(),
          Role: user.role,
          company: {
            name: user.team?.name,
            company_id: user.team?.id,
            plan: subscription.plan,
            'Is Trial': subscription.is_trial,
            'Ends At': subscription.ends_at,
          },
          hide_default_launcher: !user.vendor_id,
          custom_launcher_selector: !user.vendor_id ? '#intercom-chat' : undefined,
          alignment: user.vendor_id ? 'left' : 'right',
        })
      }

      if (hotjar.initialized()) {
        hotjar.identify(user.id.toString(), {
          team_name: user.team?.name,
          team_id: user.team?.id,
          team_plan: user.team?.subscription.plan,
          user_email: user.email,
          user_id: user.id,
          user_role: user.role,
          user_name: user.name,
        })
      }
    }
  }, [user, subscription])

  useEffect(() => {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SV)
    window.Intercom?.('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'hexn2c71',
    })
  }, [])
}

export default use3rdParties
