import { useCreate, useGetOne } from '@queries/request'
import { PatternsContentRequest, PatternsContentResponse } from '../models'

const KEYS = {
  Patterns: 'Patterns',
}

const PATHS = {
  patterns: '/core-integrations/patterns/',
  patternsByTeam: '/core-integrations/patterns/:teamId',
}

export const useCreateContent = () => {
  return useCreate<PatternsContentRequest>(PATHS.patterns)
}

export const useGetContent = (teamId?: number, enabled = true) => {
  return useGetOne<PatternsContentResponse>(
    [KEYS.Patterns],
    PATHS.patternsByTeam.replace(':teamId', teamId?.toString() || ''),
    {
      query: {
        enabled: !!teamId && enabled,
        refetchInterval: enabled ? 3000 : undefined,
      },
    },
  )
}
