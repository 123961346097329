import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { differenceInDays } from 'date-fns'

import { useGetTeamSubscription } from '@queries/Billing'
import useGetAccount from '@helpers/hooks/useGetAccount'

import LinkButton from '@components/LinkButton'
import { useGetQuestionnaireAnswers } from '@queries/Teams'
import { useGetContent } from '@pages/Onboarding/api'

import styles from '../../index.module.css'

export interface TrialCounterProps {}

export const TrialCounter: React.FC<TrialCounterProps> = () => {
  const { t } = useTranslation('common', { keyPrefix: 'trialCounter' })
  const user = useGetAccount()
  const { data: subscription } = useGetTeamSubscription({ enabled: !!user?.team?.subscription })
  const { data: answers } = useGetQuestionnaireAnswers()

  const [isPatternsContentSet, setIsPatternsContentSet] = useState(false)
  const [hasPatternsContent, setHasPatternsContent] = useState(true)
  const patternsContentEnabled = !!subscription?.is_trial && !!user?.team?.onboarded

  const { data: patternsContent } = useGetContent(
    user?.team?.id,
    patternsContentEnabled && !isPatternsContentSet && hasPatternsContent,
  )

  const diffDays = subscription?.trial_end ? differenceInDays(new Date(subscription?.trial_end), new Date()) : 0
  const patternsLink = useMemo(() => {
    const demoPath = new URL(`https://approveit.today/trial`)
    demoPath.searchParams.set('pattern', patternsContent?.pattern || '')
    demoPath.searchParams.set('firstname', user?.name?.split(' ')?.[0] || '')
    demoPath.searchParams.set('lastname', user?.name?.split(' ')?.[1] || '')
    demoPath.searchParams.set('email', user?.email || '')
    // demoPath.searchParams.set('company', user?.team?.name || '')
    // const phone = answers?.find((answer) => answer[1].startsWith('+'))?.[1]
    // demoPath.searchParams.set('phone', phone || '')

    return demoPath.toString()
  }, [patternsContent, user, answers])

  useEffect(() => {
    if (hasPatternsContent && !patternsContent?.team) {
      setHasPatternsContent(false)
    } else if (!hasPatternsContent && !!patternsContent?.team) {
      setHasPatternsContent(true)
    }

    if (patternsContentEnabled && patternsContent?.pattern && !isPatternsContentSet) {
      setIsPatternsContentSet(true)
      window?.Intercom?.('update', {
        personal_landing_link: patternsLink,
      })
    }
  }, [patternsContent, subscription, user, isPatternsContentSet, hasPatternsContent])

  const isOfferLoading = hasPatternsContent && !isPatternsContentSet

  return (
    <div className="h-12 text-sm sm:text-lg bg-violet-700 text-white flex items-center justify-center gap-2">
      <p>
        <Trans
          i18nKey="trialLeftMessage"
          count={diffDays < 0 ? 0 : diffDays + 1}
          t={t}
          components={[<span className={styles.trialCounter} key={0} />]}
        />
      </p>
      {/* <LinkButton
        to={demoPath}
        reloadDocument
        target="_blank"
        color="primary"
        type="outlined"
        className="text-sm sm:text-base px-1 sm:px-4"
      >
        {t('bookDemoLabel')}
      </LinkButton> */}
      <LinkButton
        to={patternsLink}
        reloadDocument
        target="_blank"
        color="primary"
        type="outlined"
        className="text-sm sm:text-base px-1 sm:px-4"
        disabled={isOfferLoading}
      >
        {isOfferLoading ? (
          <div className="flex gap-3 items-center">
            {t('loadingLabel')}
            <div className="flex gap-1 justify-center items-center">
              <div className="h-1 w-1 bg-black rounded-full animate-bounce [animation-delay:-0.3s]" />
              <div className="h-1 w-1 bg-black rounded-full animate-bounce [animation-delay:-0.15s]" />
              <div className="h-1 w-1 bg-black rounded-full animate-bounce" />
            </div>
          </div>
        ) : (
          t('helpLabel')
        )}
      </LinkButton>
    </div>
  )
}

export default TrialCounter
