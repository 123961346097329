import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useTable,
  Column,
  usePagination,
  useFilters,
  useGlobalFilter,
  CellProps,
  useSortBy,
  SortingRule,
} from 'react-table'
import cx from 'clsx'

import Paging from '@components/Table-v2/Paging'

import { TableRow } from './components/Row'
import { TableHeader } from './components/Header'
import { DragDropContext, DroppableProvided, OnDragEndResponder } from 'react-beautiful-dnd'
import Droppable from '@components/DndDroppable'
import typedMemo from '@helpers/typedMemo'
import Tooltip from '@src/components/Tooltip'
import Checkbox from '../Checkbox'
import { IconSquareRoundedPlus } from '@tabler/icons-react'

import styles from './index.module.css'

export { useGetPagingHelpers } from './useGetPagingHelpers'
export { useGetSearchHelpers } from '@helpers/hooks/useGetSearchHelpers'
interface Classes {
  th?: string
  thead?: string
  td?: string
  dndCell?: string
  table?: string
  tbody?: string
  tr?: string
  header?: string
  addButton?: string
  noRecordsWrap?: string
}

interface TableProps<TRecord extends object> {
  columns: Column<TRecord>[]
  data: TRecord[]
  classes?: Classes
  initPage?: number
  total?: number
  rowsPerPage?: number
  page?: number
  className?: string
  noRecordsTitle?: string
  searchValue?: string
  onRowClick?: (record: TRecord) => void
  onRowSelect?: (index: number | 'all', isChecked: boolean) => void
  handleAddClick?: () => void
  onChangePage?: (page: number) => void
  onChangeSortBy?: (rules: SortingRule<TRecord>[]) => void
  onSwap?: (({ sourceId, destinationId }: { sourceId: number; destinationId: number }) => void) | null
  thClassNames?: { [key: string]: string }
  initialSortBy?: SortingRule<TRecord>[]
}

const TableV2 = <TRecord extends object & { isSelected?: boolean; id?: number | string; className?: string } = object>(
  props: TableProps<TRecord>,
) => {
  const selectAllRef = useRef<HTMLInputElement>(null)
  const tableColumns = useMemo(() => {
    if (props.onRowSelect) {
      const newCols = [...props.columns]

      const handleSelectRows = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e?.target?.checked
        const index = e?.target?.name
        if (index === 'all') {
          props?.onRowSelect?.('all', isChecked)
        } else {
          if (!isChecked && selectAllRef.current?.checked) {
            selectAllRef.current.checked = false
          }
          props?.onRowSelect?.(+index, isChecked)
        }
      }

      newCols.unshift({
        Header: () => (
          <Checkbox
            checked={selectAllRef.current?.checked}
            onChange={handleSelectRows}
            name="all"
            ref={selectAllRef}
            className="flex items-center justify-center m-0"
          />
        ),
        id: 'select_row',
        Cell: (cell: CellProps<TRecord>) => {
          const record = cell.row?.original as TRecord
          return (
            <Checkbox
              className="flex items-center justify-center m-0"
              onChange={handleSelectRows}
              name={cell.row.id}
              checked={record.isSelected}
              onClick={(e) => e.stopPropagation()}
            />
          )
        },
      })

      return newCols
    }

    return props.columns
  }, [props.columns, props.onRowSelect])
  const {
    data = [],
    onRowClick = (record: TRecord) => null,
    classes = {},
    total = 0,
    handleAddClick,
    rowsPerPage = 0,
    onChangePage,
    initPage = 1,
    className,
    onSwap,
    thClassNames,
    initialSortBy = [],
  } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: tableColumns,
      data,
      initialState: { pageIndex: initPage - 1, pageSize: rowsPerPage || 10, sortBy: initialSortBy },
      manualPagination: true,
      manualFilters: true,
      pageCount: Math.ceil(total / rowsPerPage),
      manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const [isScrolledToLeft, setIsScrolledToLeft] = useState(false)
  const [isScrolledToRight, setIsScrolledToRight] = useState(false)
  const scrollableContainer = useRef<HTMLDivElement>(null)

  const handleGoToPage = useCallback(
    (page: number) => {
      if (props.page === undefined) {
        gotoPage(page)
      }
      onChangePage?.(page + 1)
    },
    [props.page, onChangePage],
  )

  useEffect(() => {
    if (props.page !== undefined) {
      gotoPage(props.page - 1)
    }
  }, [props.page, gotoPage])

  useEffect(() => {
    if (props.searchValue !== undefined && props.page === undefined) {
      handleGoToPage(0)
    }
  }, [props.searchValue, props.page])

  const handleContainerScroll = useCallback(() => {
    const FADE_THRESHOLD = 28
    const element = scrollableContainer.current
    if (element) {
      const isScrollable = element.scrollWidth > element.clientWidth

      if (!isScrollable) {
        setIsScrolledToRight(true)
        setIsScrolledToLeft(true)
        return
      }
      const isScrolledToLeft = element.scrollWidth - FADE_THRESHOLD <= element.clientWidth + element.scrollLeft
      const isScrolledToRight = element.scrollLeft <= FADE_THRESHOLD
      setIsScrolledToRight(isScrolledToRight)
      setIsScrolledToLeft(isScrolledToLeft)
    }
  }, [])

  useEffect(() => {
    handleContainerScroll()
    if (!!selectAllRef.current && data.length && data.every((el) => el.isSelected) && !selectAllRef.current.checked) {
      selectAllRef.current.checked = true
    }
  }, [data])

  useEffect(() => {
    if (props.onChangeSortBy) {
      props.onChangeSortBy(sortBy)
    }
  }, [sortBy])

  const handleSwap: OnDragEndResponder = useCallback(
    (e) => onSwap?.({ sourceId: e?.source?.index, destinationId: e?.destination?.index as number }),
    [onSwap],
  )

  const getBodyBase = (provided?: DroppableProvided) => {
    return (
      <tbody {...getTableBodyProps()} className={cx(classes.tbody, styles.tbody)} ref={provided?.innerRef}>
        {rows.map((row) => {
          prepareRow(row)
          const { key } = row.getRowProps()
          const index = onChangePage ? row.index + rowsPerPage * pageIndex : row.index

          return (
            <TableRow
              row={row}
              index={index}
              onRowClick={onRowClick}
              className={cx(classes.tr, styles.tr, 'group')}
              key={row.original.id || key}
              tdClassName={cx(classes.td, styles.td)}
              isDnD={!!onSwap}
            />
          )
        })}
        {provided?.placeholder}
      </tbody>
    )
  }

  const getBody = () => {
    if (onSwap) {
      return (
        <DragDropContext onDragEnd={handleSwap}>
          <Droppable droppableId="droppable">{(provided) => getBodyBase(provided)}</Droppable>
        </DragDropContext>
      )
    }

    return getBodyBase()
  }
  return (
    <div className={cx('base w-full px-4', className)}>
      <div
        className={cx(
          styles.content,
          !isScrolledToLeft && styles.isRightOverflowing,
          !isScrolledToRight && styles.isLeftOverflowing,
        )}
        onScroll={handleContainerScroll}
        ref={scrollableContainer}
      >
        <table {...getTableProps()} className={cx(classes.table, styles.table)}>
          <thead className={cx(classes.thead)}>
            {headerGroups.map((headerGroup) => {
              const { key } = headerGroup.getHeaderGroupProps()
              return (
                <TableHeader
                  key={key}
                  headerGroup={headerGroup}
                  className={cx(classes.header, styles.header)}
                  thClassName={cx(classes.th, styles.th, !onSwap && 'first:pl-[52px]')}
                  thClassNames={thClassNames}
                  isDnd={!!onSwap}
                />
              )
            })}
          </thead>
          {getBody()}
        </table>
      </div>
      {!!props.noRecordsTitle && !rows.length && (
        <div className={cx('px-3 py-6 text-grey_1 text-center', classes.noRecordsWrap)}>{props.noRecordsTitle}</div>
      )}
      <Tooltip id="sort_tip" />
      {handleAddClick && (
        <button
          className={cx('bg-white', styles.addButton, classes.addButton)}
          onClick={handleAddClick}
          type="button"
          data-qa="table-add-record"
        >
          <IconSquareRoundedPlus className="h-5" />
        </button>
      )}

      {onChangePage && pageCount > 1 && (
        <Paging
          goToPage={handleGoToPage}
          pageIndex={pageIndex}
          pageCount={pageCount}
          rowCount={page.length}
          totalRowCount={total}
          pageSize={pageSize}
        />
      )}
    </div>
  )
}

export default typedMemo(TableV2)
