import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MultiValue,
  SingleValue,
  components,
  GroupBase,
  SelectInstance,
  StylesConfig,
  CSSObjectWithLabel,
} from 'react-select'
import { AsyncProps } from 'react-select/async'
import cx from 'clsx'

import { IconCirclePlus, IconUser, IconX } from '@tabler/icons-react'
import Select, { useSelectHelpers } from '@components/Form/ReactAsyncSelect'
import Button from '@components/Button'
import { usePopupHandlers } from '@components/Popup'
import QuickUserAddPopup from '@pages/Team/Members/components/QuickUserAddPopup'

import debounce from '@helpers/debounce'
import { useGetTeamDropdownList, useGetUsersByIds } from '@queries/OrgStructure'
import { useGetConnectedChannel } from '@helpers/hooks/useGetConnectedChannel'
import useImportUsersFromChannel from '@pages/Team/Members/hooks/useImportUsersFromChannel'
import { CommunicationType, communicationTypes } from '@src/constants'
import { useIsAdmin } from '@src/helpers'
import { getInitials } from '@helpers/utils'
import useGetAccount from '@helpers/hooks/useGetAccount'

import { AddUserResponse } from '@models/User'

type TValue<IsMulti = false, T = unknown> = IsMulti extends false ? T : T[]

export interface UserSelectProps<IsMulti extends boolean = false, T = unknown> {
  value?: IsMulti extends false ? T : T[]
  label?: string
  required?: boolean
  errorMessage?: string
  onChange?: (value?: TValue<IsMulti, T>) => void
  onSelectOption?: (value: Option<T>) => void
  customOptions?: Option<T>[]
  filterOptions?: (option: Option<T>) => boolean
  menuIsOpen?: boolean
  styles?: StylesConfig<Option<T>, IsMulti, GroupBase<Option<T>>>
  excludeMe?: boolean
}

const LIST_HEADER_HEIGHT = 45

export function UserSelect<IsMulti extends boolean = false, T = unknown>({
  value,
  label,
  required = false,
  errorMessage,
  onChange,
  onSelectOption,
  isMulti,
  placeholder,
  name,
  className,
  customOptions = [],
  filterOptions,
  menuIsOpen,
  styles,
  excludeMe = false,
  ...props
}: Omit<AsyncProps<Option<T>, IsMulti, GroupBase<Option<T>>>, 'value' | 'onChange' | 'onSelectOption'> &
  UserSelectProps<IsMulti, T>) {
  const { t } = useTranslation('accounting', { keyPrefix: 'contract' })

  const isAdmin = useIsAdmin()
  const account = useGetAccount()
  const [usedOptions, setUsedOptions] = useState<Option<T>[]>([])

  const connectedChannelType = useGetConnectedChannel()
  const connectedChannel = connectedChannelType
    ? communicationTypes[connectedChannelType as unknown as CommunicationType]
    : null
  const { handleImport, isLoading } = useImportUsersFromChannel()

  const [inputValue, setInputValue] = useState('')
  const { refetch } = useGetTeamDropdownList({ search: inputValue, size: 100 }, false)

  const userIds = (Array.isArray(value) ? value : [value]) as Array<number>

  const { data: usedUsers } = useGetUsersByIds({
    ids: userIds,
    key: `[${userIds.join(',')}]`,
  })

  const getIsCustomOption = (data: Option<T>) => customOptions.some((option) => option.value === data.value)

  useEffect(() => {
    if (usedUsers?.results?.length) {
      setUsedOptions((usedUsers?.results?.map((el) => ({ value: el.id, label: el.full_name })) as Option<T>[]) || [])
    }
  }, [usedUsers?.results?.length])

  const loadOptions = useCallback(
    async (value: string) => {
      let options: Option<T>[] =
        (await refetch()).data?.results?.map((el) => ({
          label: el.full_name,
          value: el.id as T,
          roles: el.roles,
          sourceId: el.source_id,
        })) || []
      if (excludeMe) {
        options = options.filter((user) => user.value !== account?.team_user_id)
      }
      if (!value && !!customOptions?.length) {
        return [...customOptions, ...options]
      }
      return options
    },
    [excludeMe],
  )
  const options = useMemo(() => debounce(loadOptions), [])

  const { handleTogglePopup, isPopupOpen } = usePopupHandlers()

  const handleAddUser = useCallback(
    (user: AddUserResponse) => {
      refetch()
      if (isMulti) {
        onChange?.([...((value || []) as T[]), user.id] as TValue<IsMulti, T>)
      } else {
        onChange?.(user.id as TValue<IsMulti, T>)
      }
      onSelectOption?.({ label: user.full_name, value: user.id as T, roles: ['email_approver'], sourceId: null })
    },
    [value],
  )

  const { handleChange, optionValue } = useSelectHelpers<T, IsMulti>(
    [...customOptions, ...usedOptions],
    value as TValue<IsMulti, T>,
    onChange,
  )

  const handleUserChange = (option: SingleValue<Option<T>> | MultiValue<Option<T>>) => {
    setUsedOptions(() => {
      if (!option) {
        return []
      }
      if (Array.isArray(option)) {
        return option
      }

      return [option]
    })
    if (isMulti) {
      const singleOption = (option as Option<T>[])[(option as Option<T>[]).length - 1]
      onSelectOption?.(singleOption)
    } else {
      onSelectOption?.(option as Option<T>)
    }
    handleChange(option)
  }

  const selectRef = useRef<SelectInstance<Option<T>>>()

  const customStyles = {
    multiValue: (base: CSSObjectWithLabel) =>
      Object.assign(base, {
        backgroundColor: 'transparent',
      }),
  }

  const combinedStyles = {
    ...customStyles,
    ...styles,
  }

  return (
    <>
      <Select<Option<T>, IsMulti>
        styles={combinedStyles}
        menuIsOpen={menuIsOpen}
        menuPlacement="auto"
        defaultOptions
        cacheOptions
        loadOptions={options}
        onInputChange={setInputValue}
        value={optionValue || null}
        label={label}
        required={required}
        errorMessage={errorMessage}
        placeholder={placeholder}
        name={name}
        className={className}
        classNames={{
          control: () => 'cursor-text caret-transparent',
          menu: (classnames) => cx(classnames, '!w-[350px]'),
          ...props.classNames,
        }}
        refObj={selectRef as RefObject<SelectInstance<Option<T>>>}
        components={{
          MenuList: (props) => {
            const { maxHeight, ...menuListProps } = props
            const hasOptions = props.selectProps.inputValue
              ? !!props.options.filter(({ label }) =>
                  label?.toLowerCase().match(props.selectProps.inputValue?.toLowerCase()),
                ).length
              : !!props.options.length
            return (
              <div style={{ maxHeight: maxHeight }}>
                {!hasOptions && <div className="pt-2 text-center text-grey_1">{t('noUsersMessage')}</div>}
                {isAdmin && (
                  <div className="flex justify-between">
                    <Button
                      type="contained"
                      buttonType="button"
                      className={cx('p-2 w-full justify-start')}
                      onClick={() => {
                        handleTogglePopup()
                        selectRef?.current?.blur()
                      }}
                    >
                      <IconCirclePlus className="h-4 w-4" /> {t('addUserLabel')}
                    </Button>
                    {connectedChannel && (
                      <div className="flex gap-1">
                        {isLoading && (
                          <span
                            className={cx('spinner-border absolute text-center')}
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        <Button type="contained" buttonType="button" disabled={isLoading} onClick={handleImport}>
                          Import users from {connectedChannel?.label}
                          <connectedChannel.Icon className="w-4 h-4" />
                        </Button>
                      </div>
                    )}
                  </div>
                )}
                {hasOptions && <div className="border-b mb-2 mx-2" />}
                <components.MenuList {...menuListProps} maxHeight={maxHeight - LIST_HEADER_HEIGHT} />
              </div>
            )
          },
          NoOptionsMessage: () => <></>,
          Option: (props) => {
            if (filterOptions && !filterOptions(props.data)) {
              return null
            }

            const { children, data } = props
            const isCustom = getIsCustomOption(data)

            return (
              <components.Option
                {...props}
                innerProps={
                  { ...props.innerProps, 'data-qa': `${props.label}-option` } as React.DetailedHTMLProps<
                    React.HTMLAttributes<HTMLDivElement>,
                    HTMLDivElement
                  >
                }
              >
                <div className="flex items-center gap-2">
                  {isCustom ? (
                    <div className="w-5 h-5 border-blue-500 border-[1px] rounded-full flex justify-center items-center border-solid shrink-0">
                      <IconUser className="w-4 h-4 text-blue-500" />
                    </div>
                  ) : (
                    <div className="w-5 h-5 bg-blue-500 text-xs leading-5 rounded-full flex justify-center items-center text-white shrink-0">
                      {getInitials(data.label)}
                    </div>
                  )}
                  <div>{children}</div>
                </div>
              </components.Option>
            )
          },
          Placeholder: ({ children }) => {
            return (
              <div className="absolute inset-0 mx-4 flex items-center text-gray-500">
                <div className="w-6 h-6 border-neutral-500 border-[1px] rounded-full flex justify-center items-center border-dashed mr-2 shrink-0">
                  <IconUser className="w-4 h-4 text-gray-500" />
                </div>
                <div>{children}</div>
              </div>
            )
          },
          MultiValueLabel: (props) => {
            const { children, data } = props
            const isCustom = getIsCustomOption(data)

            return (
              <components.MultiValueLabel {...props}>
                <div className="flex items-center gap-2">
                  {isCustom ? (
                    <div className="w-4 h-4 border-blue-500 border-[1px] rounded-full flex justify-center items-center border-solid shrink-0">
                      <IconUser className="w-3 h-3 text-blue-500" />
                    </div>
                  ) : (
                    <div className="w-5 h-5 bg-blue-500 text-xs leading-5 rounded-full flex justify-center items-center text-white shrink-0">
                      {getInitials(data.label)}
                    </div>
                  )}
                  {children}
                </div>
              </components.MultiValueLabel>
            )
          },
          ClearIndicator: (props) => {
            return (
              <components.ClearIndicator {...props}>
                <IconX className="text-neutral-300 hover:stroke-black hover:opacity-60 transition w-4 h-4" stroke={4} />
              </components.ClearIndicator>
            )
          },
          ...props.components,
        }}
        onChange={handleUserChange}
        isMulti={isMulti}
        menuPortalTarget={document.body}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        onMenuOpen={props.onMenuOpen}
        onMenuClose={props.onMenuClose}
      />
      {isPopupOpen && <QuickUserAddPopup onClose={handleTogglePopup} onAddUser={handleAddUser} />}
    </>
  )
}

export default UserSelect
