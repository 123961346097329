import { TeamKeys, OrgStructureKeys, AccountKeys, AccountingKeys } from '../Keys'

import { useQueryClient, MutationFunction, useMutation, useQuery } from '@tanstack/react-query'
import { useCreate, useGetOne, useGetList, usePatch, useUpdate, useDelete } from '../request'

import { User, AddUserRequest, AddUserResponse, UpdateUserRequest } from '@models/User'
import {
  InviteUsersRequest,
  CheckJoinTeamIdResponse,
  JoinTeamIdRequest,
  TeamUserListItem,
  OnboardingRequestBody,
  OnboardingRequestResponse,
} from '@models/Team'
import { Answer } from '@models/Question'
import { API_ADDRESS, CommunicationType } from '@src/constants'
import axios, { AxiosResponse, AxiosError } from 'axios'
import api from '../../api'
import { sendEvent } from '@src/helpers/hooks/useAmplitude'
import { InviteData } from '@src/pages/Authorization/Auth'

export const PATHS = {
  assignTeam: '/api/my-account/team',
  slackUsers: '/bot/my-team/users',

  emailUsers: '/api/teams/users',
  connectMessendgerForUser: '/api/teams/users/:userId/connect/:integration',
  disconnectMessendgerForUser: '/api/teams/users/:userId/disconnect',
  team: '/api/teams',
  questions: '/api/teams/questions',
  validateTeamName: '/api/teams/validate',
  onboard: 'api/teams/onboarding',
  onboardingRequests: 'api/teams/onboarding-request',
  approvalRequest: '/api/teams/onboarding/approval-request',

  allUsers: '/api/teams/users',
  inviteUser: '/api/teams/invites',
  checkInvite: '/api/teams/invites',

  channel: '/api/teams/communication',

  questionnaire: '/api/teams/questionnaire',
  questionnaireAnswers: '/api/teams/answers',

  acceptInvite: '/api/auth/accept-invite',
  userRoles: '/api/auth/roles/',
  users: '/api/auth/users',

  teams: '/api/teams',

  activateApi: '/api/team/api/activate',
}

export const useGetAllUsers = (enabled = true) => {
  return useGetList<TeamUserListItem>([TeamKeys.AllUsers], PATHS.allUsers, {
    query: {
      enabled,
    },
  })
}

export const useAddTeamUser = (
  { reloadOrgStructure = true }: { reloadOrgStructure?: boolean } = { reloadOrgStructure: true },
) => {
  const cache = useQueryClient()
  return useCreate<AddUserRequest, AddUserResponse>(PATHS.emailUsers, {
    mutation: {
      onSuccess: () => {
        if (reloadOrgStructure) {
          cache.invalidateQueries([OrgStructureKeys.OrgStructure])
        }
      },
    },
  })
}

export const useDeleteTeamUser = (id?: number) => {
  const cache = useQueryClient()
  return useDelete(`${PATHS.emailUsers}/${id}`, {
    query: {
      enabled: !!id,
    },
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
      },
    },
  })
}

export const useUpdateTeamUserInfo = (id?: number | null, isMe?: boolean) => {
  const cache = useQueryClient()
  return usePatch<Partial<UpdateUserRequest>, User>(`${PATHS.emailUsers}/${id}`, {
    mutation: {
      onSuccess: () => {
        cache.invalidateQueries([OrgStructureKeys.OrgStructure])
        if (isMe) {
          cache.invalidateQueries([AccountKeys.Me])
        }
      },
    },
  })
}

export const useDisconnectMessengerFromUser = (id?: number | null) => {
  const cache = useQueryClient()
  return usePatch<Partial<UpdateUserRequest>, User>(
    PATHS.disconnectMessendgerForUser.replace(':userId', id?.toString() || ''),
    {
      mutation: {
        onSuccess: () => {
          cache.invalidateQueries([OrgStructureKeys.OrgStructure, id])
        },
      },
    },
  )
}

export const useConnectMessengerFromUser = (id?: number | null, messenger?: string) => {
  const cache = useQueryClient()
  return usePatch<Partial<UpdateUserRequest>, User>(
    PATHS.connectMessendgerForUser.replace(':userId', id?.toString() || '').replace(':integration', messenger || ''),
    {
      mutation: {
        onSuccess: () => {
          cache.invalidateQueries([OrgStructureKeys.OrgStructure, id])
        },
      },
    },
  )
}

export const useCreateOnboardingRequest = () => {
  return useCreate<OnboardingRequestBody>(PATHS.onboardingRequests)
}
export const useGetOnboardingRequest = () => {
  return useGetOne<OnboardingRequestResponse>([TeamKeys.OnboardingRequest], PATHS.onboardingRequests)
}

export const useSetOnboardedFlag = () => {
  return usePatch(PATHS.onboard)
}

export const useSetComminicationChannel = () => {
  return usePatch<{ communication: CommunicationType }>(PATHS.channel)
}

export const useAssignToTeam = () => {
  return usePatch<{ team: string }>(PATHS.assignTeam)
}

export const useAddTeam = () => {
  return useCreate<{ name: string }>(PATHS.team, {
    mutation: {
      mutationKey: [TeamKeys.AddTeam],
    },
  })
}

export const useSendQuestionnaire = () => useCreate<{ questions: Answer[] }>(PATHS.questionnaire)
export const useGetQuestionnaireAnswers = () => useGetList<[number, string]>(['Answers'], PATHS.questionnaireAnswers)

export const useSendApprovalRequest = () => {
  const cache = useQueryClient()
  return useUpdate(PATHS.approvalRequest, {
    mutation: {
      mutationKey: [TeamKeys.SendRequest],
      onSuccess: () => {
        cache.invalidateQueries([TeamKeys.Onboarding])
      },
    },
  })
}

export const useGetOnboardigStatus = () =>
  useGetOne<{ onboarding_request_sent: boolean }>([TeamKeys.Onboarding], PATHS.onboard, {
    query: {
      cacheTime: 0,
    },
  })

export const useInviteUser = (
  { reloadOrgStructure = true }: { reloadOrgStructure?: boolean } = { reloadOrgStructure: true },
) => {
  const cache = useQueryClient()
  return useCreate<InviteUsersRequest>(PATHS.inviteUser, {
    mutation: {
      onSuccess: () => {
        sendEvent('users_invited')
        if (reloadOrgStructure) {
          cache.invalidateQueries([OrgStructureKeys.OrgStructure])
        }
        cache.invalidateQueries([OrgStructureKeys.Invites])
      },
      mutationKey: [TeamKeys.InvitingUsers],
    },
  })
}

export const useAcceptInvite = () => {
  return usePatch<{ invite: string }>(PATHS.acceptInvite, {
    mutation: {
      onSuccess: () => {
        window.location.reload()
      },
    },
  })
}

export const useGetInviteData = (uuid?: string) => {
  return useQuery<InviteData, AxiosError>(
    [AccountKeys.InviteToken],
    async () => {
      const res = await axios.get<InviteData>(`${PATHS.checkInvite}/${uuid}`, {
        baseURL: API_ADDRESS,
      })

      return res.data
    },
    {
      enabled: !!uuid,
    },
  )
}

export const useCheckTeamIdToJoin = () => {
  const service: MutationFunction<CheckJoinTeamIdResponse, string> = async (
    id: string,
  ): Promise<CheckJoinTeamIdResponse> => {
    const res: AxiosResponse<CheckJoinTeamIdResponse> = await api.get(`${PATHS.teams}/${id}/join`)

    return res.data
  }
  return useMutation<CheckJoinTeamIdResponse, AxiosError<unknown>, string>({
    mutationFn: service,
  })
}

export const useJoinTeam = () => {
  const service: MutationFunction<CheckJoinTeamIdResponse, JoinTeamIdRequest> = async (
    data: JoinTeamIdRequest,
  ): Promise<CheckJoinTeamIdResponse> => {
    const res: AxiosResponse<CheckJoinTeamIdResponse> = await api.put(`${PATHS.teams}/${data.id}/join`, {
      auth: data.auth,
    })

    return res.data
  }
  return useMutation<CheckJoinTeamIdResponse, AxiosError<unknown>, JoinTeamIdRequest>({
    mutationFn: service,
  })
}

export const useGetUserRoles = () => {
  return useGetList<{ name: string }>([TeamKeys.UserRoles], PATHS.userRoles, {
    query: {
      initialData: [],
      cacheTime: Infinity,
    },
  })
}

interface ChangeRoleResponse {
  role_name: string
}

export const useChangeUserRole = (id?: number | null) => {
  return useMutation<ChangeRoleResponse, AxiosError<unknown>, { role_name: string }>(async (data) => {
    const res = await api.put<ChangeRoleResponse>(`${PATHS.users}/${id}/roles/`, data)
    return res.data
  })
}

export const useDeleteUser = (id?: number | null) => {
  return useDelete(`${PATHS.users}/${id}`)
}

export const useActivateApi = () => {
  const cache = useQueryClient()
  return useCreate(PATHS.activateApi, {
    mutation: {
      mutationKey: [TeamKeys.API],
      onSuccess: () => {
        cache.invalidateQueries([AccountKeys.Me])
      },
    },
  })
}

export { TeamKeys as Keys }
