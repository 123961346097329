import React from 'react'
import cx from 'clsx'
import { Link } from 'react-router-dom'

import { Button, ButtonProps } from '../Button'

export interface LinkButtonProps extends ButtonProps {
  to: string
  state?: unknown
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
  target?: string
  reloadDocument?: boolean
  linkClassName?: string
}

export const LinkButton: React.FC<React.PropsWithChildren<LinkButtonProps>> = ({
  to,
  state,
  onClick,
  children,
  target,
  linkClassName,
  reloadDocument,
  ...props
}) => {
  return (
    <Link
      to={!props.disabled ? to : ''}
      state={!props.disabled ? state || undefined : undefined}
      className={cx('hover:no-underline', linkClassName, props.disabled && 'pointer-events-none')}
      onClick={(e) => {
        e.stopPropagation()
      }}
      target={target}
      reloadDocument={reloadDocument}
    >
      <Button {...props} onClick={onClick}>
        {children}
      </Button>
    </Link>
  )
}

export default LinkButton
